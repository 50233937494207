@font-face {
  font-family: monumentExtended;
  src: url(./fonts/MonumentExtended-Regular.otf);
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'monumentExtended', sans-serif !important;
  font-weight: 400;
  background-color: #fff !important;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fontSmall {
  font-size: 14px !important;
}

.navbar {
  margin: 0;
  padding: 0 !important;
  backdrop-filter: blur(24px) !important;
  background-color: rgba(31,31,31,.35) !important;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(255,255,255,.2);
  transition: background-color 0.5s ease;
  border-bottom: 1px solid rgba(242,12,38, .18);
  backdrop-filter: blur(180px);
}

.header__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 80px;
  width: 100%;
}

.header__logo {
  z-index: 1;
  width: auto;
  height: 80px;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.header__logo img {
  height: 80px;
  width: auto;
  position: relative;
  top: 0;
}

.section {
  position: relative;
  overflow: hidden;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section--pb0 {
  padding-bottom: 0;
}

.section--first {
  padding-top: 130px;
}

.stakingMofo {
  text-align: center;
  height: auto;
  position: relative;
  bottom: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
}

.vanta-canvas {
  position: fixed !important;
}

@media (max-width: 992px) {
  .stakingMofo {
    width: 100%;
  }
}

.staking {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  border-radius: 10px;
  position: relative;
  padding: 20px;
  background: rgba(255,255,255,.8);
  min-height: 527px;
  height: 100%;
}

.stake_info_general {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.staking__info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  border-bottom: 2px solid rgba(255,0,0,0.12);
  padding-bottom: 30px;
}

.staking__info h2 {
  font-size: 20px;
  line-height: 24px;
  color: #25292e;
  font-weight: 500;
  margin-bottom: 10px;
}

.staking__info p {
  font-size: 16px;
  line-height: 24px;
  color: rgba(255,0,0,0.65);
  margin-bottom: 0;
  font-weight: 500;
  width: 100%;
}

.message-stakeing {
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  color: #25292e;
  width: 100%;
}

.top-40 {
  margin-top: 40px !important;
}

button:disabled {
  opacity: .35;
}

.staking__group {
  margin-top: 30px;
  width: 100%;
}

.stake_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.elmoBlack {
  color: #25292e !important;
}

.staking__label {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #25292e;
  margin-bottom: 15px;
  border: 2px solid rgba(255,0,0,0.25);
  border-radius: 10px;
  padding: 5px 12px;
  width: 165px;
  text-align: center;
}

.staking__label_second {
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #25292e;
  margin-bottom: 15px;
  border: 2px solid rgba(255,0,0,0.25);
  border-radius: 10px;
  padding: 5px 12px;
  text-align: center;
}

.staking__label_second .amount {
  color: #25292e;
  font-size: 21px;
  font-weight: 500;
}

.staking__label_second .label {
  color: #25292e;
  font-size: 14px;
  font-weight: 500;
}

.staking__label_1 {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #25292e;
  width: 100%;
  line-height: 100%;
  margin-bottom: 15px;
}

.staking__input {
  width: 100%;
  height: 48px;
  padding: 0 15px;
  border: 2px solid rgba(255,0,0,0.25);
  background-color: transparent;
  border-radius: 10px;
  font-size: 15px;
  color: #25292e;
  cursor: default;
  font-weight: 500;
}

.staking__input:hover, .staking__input:focus {
  border-color: rgba(255,0,0,0.4);
  outline: none;
}

.staking__btn {
  position: relative;
  width: 100%;
  height: 48px;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: rgba(255,0,0,0.5) !important;
  border-radius: 10px !important;
  color: #fff !important;
  text-transform: uppercase;
  font-size: 15px !important;
  font-weight: 700 !important;
  letter-spacing: 1px;
  border: 2px solid rgba(255,0,0,0.5) !important;
  margin-top: 15px;
}

.loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: rgba(255,0,0,0.5);
  font-size: 16px;
  text-align: center;
  line-height: 100px;
  background: rgba(255,255,255, .9);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.loader-wrap.not-connected {
  height: 275px !important;
  bottom: 0 !important;
  top: unset !important;
}

.spinner-border {
  width: 45px !important;
  height: 45px !important;
}

.header__nav {
  position: fixed;
  width: 280px;
  top: 80px;
  bottom: 0;
  left: 0;
  background-color: rgba(255,255,255,.2);
  padding: 30px 15px 0;
  transition: transform 0.5s ease;
  transform: translate3d(-280px, 0, 0);
  border-top: 1px solid rgba(242,12,38, .1);
}

.header__nav li {
  display: block;
  margin-bottom: 25px;
}

.header__nav a {
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #25292e;
  text-transform: uppercase;
}

.header__nav a:hover {
  color: #fff;
}

a {
  text-decoration: none !important;
  transition: 0.5s ease;
  transition-property: color, background-color, border-color, box-shadow;
}

ul {
  margin: 0 !important;
  padding: 0;
  list-style: none;
}

@media (max-width: 430px) {
  .loader-wrap.not-connected {
    height: 225px !important;
  }
}

@media (min-width: 576px) {
  .header__nav {
    padding-left: calc((100vw - 516px) / 2);
    width: 320px;
    transform: translate3d(-320px, 0, 0);
  }
}

@media (min-width: 768px) {
  .section {
      padding: 70px 0;
  }
  .section--pb0 {
    padding-bottom: 0;
  }
  .section--first {
    padding-top: 180px;
  }
  .staking {
    padding: 40px;
  }
  .staking__info {
    margin-bottom: 10px;
  }
  .staking__info h2 {
    font-size: 24px;
    line-height: 24px;
  }
  .staking__info p {
    font-size: 18px;
    line-height: 24px;
  }
  .staking__group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;
  }
  .stake_info {
    flex-direction: row;
  }
  .header__nav {
    padding-left: calc((100vw - 696px) / 2);
  }
}

@media (min-width: 992px) {
  .header__nav {
    padding-left: calc((100vw - 936px) / 2);
  }
}

@media (max-width: 992px) {
  .btm {
    margin-bottom: 25px;
  }
}

@media (min-width: 1400px) {
  .staking__label {
    width: 205px;
  }
}

@media (max-width: 1200px) {
  .staking__label {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .header__logo {
    margin: 0;
  }
  .header__nav {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    width: auto;
    transform: translate3d(0, 0, 0);
    padding: 0;
    margin-right: auto;
    margin-left: 60px !important;
    border-top: none;
    z-index: 2;
  }
  .header__nav li {
    display: block;
    margin-right: 44px;
    margin-bottom: 0;
  }
  .header__nav a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    color: #25292e;
    font-weight: 400;
    text-transform: none;
  }
}

.show-counter {
  padding: 0.5rem;
}

.show-counter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.75rem;
  padding: 0.5rem;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  text-decoration: none;
  color: #25292e;
  width: 100%;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: rgba(255,0,0,0.5);
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}

.custom-card {
  background-color: #10131d !important;
  box-shadow: 0 0 10px 2px rgba(242,12,38, .3);
}

.card-title {
  font-size: 1.45rem;
  text-transform: uppercase;
  color: rgb(242,12,38);
  letter-spacing: 1px;
}

.card-text {
  font-size: 1.35rem;
  font-weight: 500;
  margin: 0.5rem 0;
  color: #d5d5d5;
}
